$icon-font-path: '../fonts/';

$grid-columns: 24;

$grid-gutter-width: 20px;

$container-large-desktop: 1200px;

$grid-float-breakpoint:    993px;

$red: #db0000;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.navbar{
  margin-bottom: 0;
}
.navbar-brand{
  height: auto;
  padding: 5px 10px;
  img{
    width:215px;
    @media(max-width: $screen-sm){
      width: 150px;
    }
  }
}
.navbar-default{
  background:transparent;
  border: none;
  .navbar-nav{
    >li{
      a{
        padding-top: 40px;
        @media(max-width: $screen-sm){
          padding-top: 10px;
        }
      }
    }
    > .active{
      a{
        color: white;
        background-color: $red;
        border-bottom-right-radius:8px;
        border-bottom-left-radius:8px;
        @media(max-width: $screen-sm){          
          border-bottom-right-radius:0px;
          border-bottom-left-radius:0px;
        }
        &:hover{
          background-color: #d20202;
          color: white;
        }
      }
    }      
  }
}

.breadcrumb{
  background-color: transparent;
  font-size: 13px;
  margin:10px 0;
  a{
    color: #555555;
    text-decoration: none;
  }
  >.active{
    color: $red;
    font-size: 13px;
  }
}

.btn-red{
  border-radius: 8px;
  border:1px solid $red;
  text-transform: uppercase; 
  color: $red;
  padding: 7px 56px 7px 25px;
  margin-top: 10px;
  background: url('../images/login-icon.jpg') right top no-repeat white;
  margin-bottom: 25px;
  transition: 0.2s ease-in-out;
}

.btn-load{
  border-radius: 8px;
  background-color:$red;
  padding:8px 30px ;
  text-transform:uppercase;
  color:white;
  transition: 0.2s ease-in-out;
  margin:20px 0 50px;
}

.row{
  margin-left: 0;
  margin-right: 0;
}

.login-form{
  label{
    font-size: 13px;
    color:$red;
    font-weight: normal;
  }
  .form-control{
    background-color: transparent;
    border:1px solid $red;
    border-radius: 8px;
  }
  .btn-login{
    border-radius: 8px;
    border:1px solid $red;
    text-transform: uppercase; 
    color: $red;
    padding: 7px 56px 7px 25px;
    margin-top: 10px;
    background: url('../images/login-icon.jpg') right top no-repeat white;
  }
}

#home{
  background: url('../images/banner-home.jpg') no-repeat top center;
  background-size:  cover;
  min-height: 86vh;
  @media(max-width: $screen-sm) {
    padding: 40px 0;
  }
  .pull-right{
    min-height: 86vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-title{
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    padding:10px 30px;
    border-top-right-radius:8px;
    border-top-left-radius:8px;
    h1{
      text-transform: uppercase;
      font-size: 28px;
      margin:10px 0;
    }
  }
  .home-body{
    background-color: rgba(255, 255, 255, 0.95);
    color: white;
    padding:10px 30px;
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px;
    .home-body-text{
      color: #555555;
      margin:10px 0;
      font-size: 13px;
      line-height: 25px;
    }
  }
  .download-box{
    width:250px;
    padding:25px 25px 15px;
    position: absolute;
    margin-left: 20px;
    background-color: $red;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @media(max-width: $screen-sm) {
      position: relative;
      clear: both;
      margin: 0 auto;
    }
    a{
      font-size: 22px;
      color: white;
      text-transform:uppercase;
      display: block;
      transition: 0.2s ease-in-out;
      span{
        display: block;
        text-align: center;
        font-size: 12px;
        text-transform:none;
      }
      &:hover{
        text-decoration: none;
        opacity: 0.8;
      }
    }
    .down{
      position: absolute;
      bottom: -17px;
      left:0;
      right:0;
      margin:0 auto;
    }
  }
}

#login{
  background: url('../images/banner-login.jpg') no-repeat top left;
  background-size:  cover;
  min-height:86vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-header{
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    padding:10px 30px;
    border-top-right-radius:8px;
    border-top-left-radius:8px;
    h1{
      text-transform: uppercase;
      font-size: 28px;
      margin:10px 0;
    }
  }
  .login-body{
    background-color: rgba(255, 255, 255, 0.95);
    color: white;
    padding:10px 30px;
    border-bottom-right-radius:8px;
    border-bottom-left-radius:8px;
    .login-form-header{
      color: #555555;
      margin:10px 0;
      font-size: 14px;
      line-height: 25px;
    }    
  }
}
.content{
  background: url('../images/body-bg.jpg') repeat top left;
  .about-header{
    background-color: $red;
    color: white;
    padding:10px 30px;
    border-top-right-radius:13px;
    border-top-left-radius:13px;
    h1{
      text-transform: uppercase;
      font-size: 24px;
      margin:10px 0;
      text-align: center;
    }
  }
  .about-body{
    background-color: rgba(255, 255, 255, 0.95);
    color: white;
    padding:30px;
    border-bottom-right-radius:13px;
    border-bottom-left-radius:13px;
    margin-bottom: 30px;
    .about-featured{
      max-width: 100%;
      margin:0 auto;
      object-fit:cover;
      @media(max-width: $screen-sm) {
        margin-bottom: 20px;
      }
    }
    .about-content{
      h2{
        color: $red;
        font-size: 26px;
        line-height: 32px;
        margin-top: 0;
      }
      .about-text{
        p{
          color: #555;
          font-size: 18px;
          line-height: 36px;
          margin-bottom: 40px;
          text-align: justify;
        }
      }
    }
  }
  .project-wrapper{
    margin-bottom:25px;
    .project-header{
      background-color: $red;
      color: white;
      padding:10px 30px;
      border-top-right-radius:13px;
      border-top-left-radius:13px;
      h1{
        text-transform: uppercase;
        font-size: 20px;
        margin:7px 0;
        text-align: center;
      }
    }
    .project-body{
      background-color: rgba(255, 255, 255, 0.95);
      color: white;
      padding:0;
      border-bottom-right-radius:13px;
      border-bottom-left-radius:13px;
      margin-bottom: 30px;
      .project-featured{
        padding: 0;
        .project-featured-img{
          width: 100%;
          margin:0 auto;
          object-fit:cover;
          @media(max-width: $screen-sm) {
            margin-bottom: 20px;
          }
          &.left{
            border-bottom-left-radius:13px;
          }
          &.right{
            border-bottom-right-radius:13px;
          }
        }
      }
      .project-content{
        padding:20px 30px;
        p{
          color: #555;
          font-size: 16px;
          line-height: 28px;
          a{
            color:$red;
            text-decoration:underline;
          }
        }
      }
    }
  }
  .contact-header{
    background-color: $red;
    color: white;
    padding:10px 30px;
    border-top-right-radius:13px;
    border-top-left-radius:13px;
    h1{
      text-transform: uppercase;
      font-size: 24px;
      margin:10px 0;
      text-align: center;
    }
  }
  .contact-body{
    background-color: rgba(255, 255, 255, 0.95);
    color: white;
    padding:40px 20px;
    border-bottom-right-radius:13px;
    border-bottom-left-radius:13px;
    margin-bottom: 30px;
    .contact-featured{
      width: 100%;
      height:200px;
      margin:0 auto;
      object-fit:cover;
      @media(max-width: $screen-sm) {
        margin-bottom: 20px;
      }
    }
    .contact-text{
      text-align:justify;
      font-size:14px;
      color:#555;
      margin:15px 0;
      line-height:28px;
    }
    label{
      font-size:16px;
    }
    .address{
      font-size:14px;
      color:#555;
      padding: 5px 0 5px 35px;
      margin-bottom:5px;
      &.icon{
        background:url('../images/icon-address.jpg') no-repeat left top
      }
      &.mail{
        background:url('../images/icon-mail.jpg') no-repeat left top
      }
      &.phone{
        background:url('../images/icon-mobile.jpg') no-repeat left top
      }
      &.mobile{
        background:url('../images/icon-phone.jpg') no-repeat left top
      }
      &.red{
        color:$red;
      }
    }
  }
  .body-download{
    padding:20px;
    .download-title{
      color:$red;
      font-size:18px;
      font-weight: normal;
    }
    .project-title{ 
      background:url('../images/folder.png') no-repeat left top;
      padding-left: 40px;
      padding-top: 10px;
    }
    .download-project {
        margin:10px 0;
      li{
        list-style:circle;
        margin-left:20px;
        margin-bottom:5px;
        a{
          color:#555;
          text-decoration:none;
        }
        a > img{
          margin-right:7px;
        }
      }      
    }
    .sub-download-project > li{
      list-style:circle;
    }
  }  
}

.logout{
  @media(max-width:$screen-sm){
    text-align: center;
  }
}

#banner{  
  height: 180px;
  &.about{
    background: url('../images/banner-about.jpg') no-repeat top center;
    background-size:  cover;
  }
  &.project{
    background: url('../images/banner-project.jpg') no-repeat top center;
    background-size:  cover;
  }
  &.contact{
    background: url('../images/banner-contact.jpg') no-repeat top center;
    background-size:  cover;
  }
  &.download{
    background: url('../images/banner-download.jpg') no-repeat top center;
    background-size:  cover;
  }
}

footer{
  background-color: $red;
  padding:15px 0;
  text-align: center;
  color: white;
  font-size: 12px;
  border-top: 5px solid white; 
  .col-md-24{
    @media(max-width: $screen-sm){
      padding:0 30px;
    }
  } 
}